export function getSteamFromConfig(isMobile) {
  return { 'will-change': 'opacity, filter', opacity: 0, filter: isMobile ? 'blur(20px)' : 'blur(40px)' }
}

export function getSteamToConfig(stagger) {
  return { duration: 2, ease: 'power4.out', opacity: 1, filter: 'blur(0px)', stagger }
}

export const mmConfig = {
  isMobile: '(max-width: 767px)',
  isDesktop: '(min-width: 768px)',
}
