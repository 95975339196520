import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useGSAP } from '@gsap/react'
import { SplitText } from 'gsap/SplitText'
import { Icon } from '/features/buildingBlocks/Icon'
import { getSteamFromConfig, getSteamToConfig } from '/util/gsapHelpers'

import winstLeft from '/images/profit/winst-left.raw.svg'
import winstRight from '/images/profit/winst-right.raw.svg'
import { images } from '/images'

import styles from './Profit.css'

gsap.registerPlugin(ScrollTrigger, SplitText)

export function Profit() {
  const componentRef = React.useRef()
  const layoutRef = React.useRef()
  const headerRef = React.useRef(null)
  const headingRef = React.useRef(null)
  const descriptionsRef = React.useRef(null)
  const description1Ref = React.useRef(null)
  const description2Ref = React.useRef(null)

  useGSAP(
    () => {
      const mm = gsap.matchMedia()

      const heading = new SplitText(headingRef.current, { type: 'words' })

      mm.add(
        {
          isMobile: '(max-width: 767px)',
          isDesktop: '(min-width: 768px)',
        },
        (ctx) => {
          const { isMobile } = ctx.conditions
          const tlMaster = gsap.timeline()

          tlMaster
            .add(tlHeading())
            .add(tlCuttin(), '-=1')

          function tlHeading() {
            const tl = gsap.timeline()
            tl.fromTo(heading.words,
              getSteamFromConfig(isMobile),
              getSteamToConfig({ each: 0.10 })
            )
            return tl
          }

          function tlCuttin() {
            const tl = gsap.timeline()
            tl
              .set('[data-pieces]', { autoAlpha: 1, rotate: 5 })
              .fromTo('[data-piece1]', { opacity: 1, y: 0, x: 0 }, { opacity: 0, y: -8, x: 6, duration: 2, ease: 'power2.out' } )
              .fromTo('[data-piece2]', { opacity: 1, y: 0, x: 0 }, { opacity: 0, y: -8, x: 10, duration: 2, ease: 'power3.out' }, '-=1.9')
              .add('split', '-=2')
              .to('[data-profit1]', { transformOrigin: 'bottom right', x: isMobile ? -15 : -25, rotate: isMobile ? -4 : -10, duration: 0.5, ease: 'power4.inOut' }, 'split' )
              .to('[data-profit2]', { transformOrigin: 'bottom left', x: isMobile ? 15 : 20, rotate: isMobile ? 8 : 16, duration: 0.6, ease: 'power4.inOut' }, 'split' )
            return tl
          }

          ScrollTrigger.create({
            trigger: componentRef.current,
            animation: tlMaster,
            start: 'top 20%',
          })

          const tlDescription = gsap.timeline({
            scrollTrigger: {
              trigger: descriptionsRef.current,
              start: 'top 70%',
            }
          })

          tlDescription
            .fromTo(description1Ref.current, { y: -15, opacity: 0 }, { y: 0, opacity: 1, duration: 1.25, ease: 'power1.out' })
            .fromTo(description2Ref.current, { y: -15, opacity: 0 }, { y: 0, opacity: 1, duration: 1.25, ease: 'power1.out' }, '-=0.5')
        }
      )
    }, { scope: componentRef })

  return (
    <article className={styles.component} ref={componentRef}>
      <div className={styles.layout} ref={layoutRef}>
        <header className={styles.header} ref={headerRef}>
          <h2 className={styles.heading} ref={headingRef}>Snij vervolgens de winst doormidden.</h2>
        </header>
        <div className={cx(styles.cuttingBoardContainer)}>
          <img className={cx(styles.cuttingBoardImage, styles.isMobile)} src={images.cuttingBoardMobile} alt="" />
          <img className={cx(styles.cuttingBoardImage, styles.isDesktop)} src={images.cuttingBoardDesktop} alt="" />
          <div className={styles.profitContainer}>
            <div className={cx(styles.profit, styles.profit1)} data-profit1>
              <Icon icon={winstLeft} layoutClassName={styles.profitLayout} />
            </div>
            <div className={styles.pieces} data-pieces>
              <svg width="78" height="501" viewBox="0 0 78 501" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M43.8353 176.808L77.9155 62.4568L55.3162 60.3155L43.8353 176.808Z" fill="#FFF8EF" data-piece1 />
                <path d="M0 14.0899L24.6574 500.584V24.6574V0L0 14.0899Z" fill="#FFF8EF" data-piece2 />
              </svg>
            </div>
            <div className={cx(styles.profit, styles.profit2)} data-profit2>
              <Icon icon={winstRight} layoutClassName={styles.profitLayout} />
            </div>
          </div>
          <div className={styles.clothContainer} data-doekje>
            <img className={styles.cloth} src={images.cloth} alt="" />
          </div>
        </div>
        <div className={styles.descriptions} ref={descriptionsRef}>
          <p className={styles.description} ref={description1Ref}> Gebruik de ene helft om het<br /> bedrijf steeds beter te maken. </p>
          <p className={styles.description} ref={description2Ref}> Verdeel de andere helft over verschillende sociale initiatieven. </p>
        </div>
      </div>
    </article>
  )
}
